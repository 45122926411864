exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-500-index-jsx": () => import("./../../../src/pages/500/index.jsx" /* webpackChunkName: "component---src-pages-500-index-jsx" */),
  "component---src-pages-es-500-index-jsx": () => import("./../../../src/pages/es/500/index.jsx" /* webpackChunkName: "component---src-pages-es-500-index-jsx" */),
  "component---src-pages-es-cancelar-suscripcion-index-jsx": () => import("./../../../src/pages/es/cancelar-suscripcion/index.jsx" /* webpackChunkName: "component---src-pages-es-cancelar-suscripcion-index-jsx" */),
  "component---src-pages-es-index-jsx": () => import("./../../../src/pages/es/index.jsx" /* webpackChunkName: "component---src-pages-es-index-jsx" */),
  "component---src-pages-es-por-que-lupkynis-index-jsx": () => import("./../../../src/pages/es/por-que-lupkynis/index.jsx" /* webpackChunkName: "component---src-pages-es-por-que-lupkynis-index-jsx" */),
  "component---src-pages-es-que-es-la-nefritis-lupica-index-jsx": () => import("./../../../src/pages/es/que-es-la-nefritis-lupica/index.jsx" /* webpackChunkName: "component---src-pages-es-que-es-la-nefritis-lupica-index-jsx" */),
  "component---src-pages-es-que-es-lupkynis-index-jsx": () => import("./../../../src/pages/es/que-es-lupkynis/index.jsx" /* webpackChunkName: "component---src-pages-es-que-es-lupkynis-index-jsx" */),
  "component---src-pages-es-recursos-index-jsx": () => import("./../../../src/pages/es/recursos/index.jsx" /* webpackChunkName: "component---src-pages-es-recursos-index-jsx" */),
  "component---src-pages-es-registro-confirmado-index-jsx": () => import("./../../../src/pages/es/registro-confirmado/index.jsx" /* webpackChunkName: "component---src-pages-es-registro-confirmado-index-jsx" */),
  "component---src-pages-es-registro-index-jsx": () => import("./../../../src/pages/es/registro/index.jsx" /* webpackChunkName: "component---src-pages-es-registro-index-jsx" */),
  "component---src-pages-es-se-confirmo-la-cancelacion-de-la-suscripcion-index-jsx": () => import("./../../../src/pages/es/se-confirmo-la-cancelacion-de-la-suscripcion/index.jsx" /* webpackChunkName: "component---src-pages-es-se-confirmo-la-cancelacion-de-la-suscripcion-index-jsx" */),
  "component---src-pages-es-se-confirmo-la-renovacion-de-la-suscripcion-index-jsx": () => import("./../../../src/pages/es/se-confirmo-la-renovacion-de-la-suscripcion/index.jsx" /* webpackChunkName: "component---src-pages-es-se-confirmo-la-renovacion-de-la-suscripcion-index-jsx" */),
  "component---src-pages-es-tomar-lupkynis-index-jsx": () => import("./../../../src/pages/es/tomar-lupkynis/index.jsx" /* webpackChunkName: "component---src-pages-es-tomar-lupkynis-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-registration-confirmed-index-jsx": () => import("./../../../src/pages/registration-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-registration-confirmed-index-jsx" */),
  "component---src-pages-registration-index-jsx": () => import("./../../../src/pages/registration/index.jsx" /* webpackChunkName: "component---src-pages-registration-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-resubscribe-confirmed-index-jsx": () => import("./../../../src/pages/resubscribe-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-resubscribe-confirmed-index-jsx" */),
  "component---src-pages-taking-lupkynis-index-jsx": () => import("./../../../src/pages/taking-lupkynis/index.jsx" /* webpackChunkName: "component---src-pages-taking-lupkynis-index-jsx" */),
  "component---src-pages-unsubscribe-confirmed-index-jsx": () => import("./../../../src/pages/unsubscribe-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-confirmed-index-jsx" */),
  "component---src-pages-unsubscribe-index-jsx": () => import("./../../../src/pages/unsubscribe/index.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-index-jsx" */),
  "component---src-pages-what-is-lupkynis-index-jsx": () => import("./../../../src/pages/what-is-lupkynis/index.jsx" /* webpackChunkName: "component---src-pages-what-is-lupkynis-index-jsx" */),
  "component---src-pages-what-is-lupus-nephritis-index-jsx": () => import("./../../../src/pages/what-is-lupus-nephritis/index.jsx" /* webpackChunkName: "component---src-pages-what-is-lupus-nephritis-index-jsx" */),
  "component---src-pages-why-lupkynis-index-jsx": () => import("./../../../src/pages/why-lupkynis/index.jsx" /* webpackChunkName: "component---src-pages-why-lupkynis-index-jsx" */)
}

